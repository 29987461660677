import React from 'react'

import Navbar from '../components/Navbar/Index'
import Footer from '../components/Footer/Index'

const Default = ({children}) => {
    return (
        <>
            <Navbar/>
            {children}
            <Footer/>
        </>
    )
}

export default Default