import styled from 'styled-components'

const LoginButton = styled.button`
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  border: solid 2px white;
  margin: 0 1em;
  padding: 0.50rem 0.75rem;
  border-radius: 10px;
  &:hover {
    background-color: white;
    color: black;
    
  }
`
export default LoginButton