import styled from 'styled-components'



const Button = styled.button`
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  color: white;
  border: none;
  margin: 0.3em 0;
  &:hover {
    color: #58BD58;
    font-weight:700;
    
  }
`

export default Button
