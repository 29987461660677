import React from 'react'
import Carousel from 'react-elastic-carousel'
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import {Paper,
Grid,
} from '@material-ui/core/'



// import Card from '../utils/partials/CardsCarousel'
import Item from '../../components/Carousel/Item'
import trilha from '../../assets/imgs/trilha.png'
import curso from '../../assets/imgs/Curso-bulltopedia.webp'
import simulador from '../../assets/imgs/simulador.png'
import Articles from '../../components/Artigos/Articles'
import ArticlesTwo from '../../components/Artigos/ArticlesTwo'



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    spacer: {
        marginTop: '3%',
    },
    paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow:'0 4px 6px 0 #33333333',
    padding: 0,
    },
    banners: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    carouselSlider: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    publi: {
        width:'80%',
        display: 'block',
        margin: '0 auto'
    },
    publiTrilha: {
        width:'60%',
        display: 'block',
        margin: '0 auto'
    },
    publiButton: {
        padding: '0.5rem 1.25rem',
        border: 'none',
        fontWeight: '600',
        margin: '3% 0',
        backgroundColor: '#000',
        borderRadius: '7px',
        color: 'white',
        cursor: 'pointer',
        TransitionEvent: 'all 0.3s easy-in'
    },
    publiHeadigText: {
        margin: '6% 0 0'
    },
    styleVideos: {
        backgroundColor: 'black',
        width: '100%',
        display: 'flex',
        padding: '1.25rem',
        borderRadius: '10px'
    },
    videoDivs: {
        borderRadius: '10px',
        margin: '1.5%',
        width: '40%'
    },
    videoDivOne: {
        borderRadius: '8px',
        height: '300px',
        width: '545px',
        margin: '2.5% 1.5%'
    },
    videoDivTwo: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    bulltopediaNegocios: {
        backgroundColor: '#F5F5F6',
        margin: '2% 0',
        borderRadius: '10px',
        padding: '1.25rem'
    }
}));  

const breakPoints = [
    {width: 550, itemToShow: 2},
    {width: 768, itemToShow: 3},
    {width: 1200, itemToShow: 4},
    {width: 1400, itemToShow: 4},
]

const Home = () => {
    const classes = useStyles()
    return (
        <section className='container'>
            <Grid container spacing={2}>
                <Grid className={classes.spacer} item xs={12} sm={6} md={4}>
                    <Paper elevation={3} className={classes.paper}><Articles /></Paper>
                </Grid>
                <Grid className={classes.spacer} item xs={12} sm={6} md={4}>
                    <Paper elevation={3} className={classes.paper}><ArticlesTwo /></Paper>
                </Grid>
                <Grid className={classes.spacer} item xs={12} sm={6} md={4}>
                    <Paper elevation={3} className={classes.paper}>
                        <div>
                            <Carousel breakPoints = {breakPoints}>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                                <Item img={'https://picsum.photos/400/200'} >
                                </Item>
                            </Carousel>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container className={classes.bulltopediaNegocios} spacing={2}>
                <Grid className={classes.banners} item xs={12} sm={6} md={4}>
                    <div>
                        <img className={classes.publi} src={simulador} alt='Simulador Bullseye'/>
                        <h3 className={classes.publiHeadigText}>Simulador - Bullseye</h3>
                        <p>Competir sem riscos com $ 100.000 em dinheiro virtual.</p>
                        <button className={classes.publiButton}>Iniciar</button>
                    </div>
                </Grid>
                <Grid className={classes.banners} item xs={12} sm={6} md={4}>
                    <div>
                        <img className={classes.publiTrilha} src={trilha} alt='Trilha do investidor'/>
                        <h3>Trilha do Investidor</h3>
                        <p>Quer saber qual é a melhor trilha para seu estilo de vida?</p>
                        <button className={classes.publiButton}>Conheça</button>
                    </div>
                </Grid>
                <Grid className={classes.banners} item xs={12} sm={6} md={4}>
                    <div>
                        <img className={classes.publi} src={curso} alt='Curso: A Jornada'/>
                        <h3 className={classes.publiHeadigText}>Curso: A Jornada</h3>
                        <p>Conheça o Curso que mudará completamente sua vida.</p>
                        <button className={classes.publiButton}>Conheça</button>
                    </div>
                </Grid>
            </Grid>
            <section>
                <h2>Artigos</h2>
            </section>
                <Carousel breakPoints = {breakPoints}>
                    <Item>One</Item>
                    <Item>Two</Item>
                    <Item>Three</Item>
                    <Item>Four</Item>
                    <Item>Five</Item>
                    <Item>Six</Item>
                    <Item>Seven</Item> 
                </Carousel>
            <section className={classes.styleVideos}>
                <div>
                    <iframe className={classes.videoDivOne} src="https://www.youtube.com/embed/ML9LhHKxeI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className={classes.videoDivTwo}>
                    <iframe className={classes.videoDivs} src="https://www.youtube.com/embed/ML9LhHKxeI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe className={classes.videoDivs} src="https://www.youtube.com/embed/ML9LhHKxeI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe className={classes.videoDivs} src="https://www.youtube.com/embed/ML9LhHKxeI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe className={classes.videoDivs} src="https://www.youtube.com/embed/ML9LhHKxeI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </section>
                {/* <Carousel breakPoints = {breakPoints}>
                    <Item img={'https://picsum.photos/200/200'} />
                    <Item img={'https://picsum.photos/200/200'} />
                    <Item img={'https://picsum.photos/200/200'} />
                    <Item img={'https://picsum.photos/200/200'} />
                    <Item img={'https://picsum.photos/200/200'} />
                    <Item img={'https://picsum.photos/200/200'} />
                </Carousel> */}
            <Grid container>
                <Grid>
                    <h2>Artigos</h2>

                </Grid>
            </Grid>
            <Link to="/artigos">
                <h1>Hello World estou na Home</h1>
            </Link>
        </section>
    )
}

export default Home