import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Paper,
Grid
} from '@material-ui/core/'

import Articles from '../../components/Artigos/Articles'


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow:'0 4px 6px 0 #33333333',
    },
    paperArticles: {
      textAlign: 'center',
      width:'80%',
      color: theme.palette.text.secondary,
      boxShadow:'0 4px 6px 0 #33333333',
    },
    spacer: {
      marginTop: '3%',
    },
    alinhamento: {
      display: 'flex',
      justifyContent: 'center',
    }
  }));

const Category = () => {
    const classes = useStyles();
        <Grid container spacing={3}>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}>Alo</Paper>
            </Grid>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
            </Grid>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
            </Grid>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
            </Grid>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
            </Grid>
            <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
            </Grid>
        </Grid>

}

export default Category

