import React from 'react'
import { Routes, Route } from "react-router-dom"
import Grid from '@material-ui/core/Grid'

import Home from './views/Home/Home'
import Artigos from './views/Artigos/Artigos'
import Standard from './components/Artigos/StandardArticle'
import Default from './layouts/Default'
import Category from './views/Category/index'
import ApiService from './utils/ApiService/ApiService'

class App extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      artigos: [],
      categorias: [],
    };
  }

  // componentDidMount() {
  //   ApiService.ListaArtigos().ListaCategorias()
  //   .then(res => {
  //       this.setState({artigos : [...this.state.artigos, ...res.data], categorias: [...this.state.categorias, ...res.data]})
  //   })
  // }

  render() {
    return (
      <Default>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/artigos" element={<Artigos />} />
            <Route path="/artigos-particulares" element={<Standard />} />
            <Route path="/fundos-imobiliarios" element={<Category />} />
          </Routes>
          <Grid artigos={this.state.artigos} categorias={this.state.categorias}></Grid>
      </Default>
    )
  }
}

export default App;
