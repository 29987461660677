import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


import './Articles.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    },
    spacerText: {
        padding: theme.spacing(1),
    },
  }))



const ArticlesFour = () => {

    const classes = useStyles()

    return (
            <Link className={classes.textColor} to="/artigos-particulares">
                <article className="secundario">
                    <img className='imagem-dos-artigos' src={'https://blog.bullseye.com.br/wp-content/uploads/2021/08/stock-trading-6525084_1920-1024x683.jpg'} alt="flash"/>
                    <h1 className={classes.spacerText}>Quando vender uma ação?</h1>
                    <p className={classes.spacerText}>Quando alguém compra uma ação, provavelmente pensou em alguma estratégia para futuramente vender esse ativo. Sua estratégia pode ser lucrar com a alta do ativo no curto prazo, no médio prazo. Pode investir pensando no longo prazo e nos dividendos que essa empresa vai te pagar com o passar do tempo. Entenda agora os motivos para vender uma ação!</p>
                </article>
            </Link>
    )
    
}

export default ArticlesFour