import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import './MainArticle.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    }
  }))


const MainArticle = () => {
    const classes = useStyles()


    const preventDefault = (event) => event.preventDefault();

    return (
        <Typography>
            <Link className={classes.textColor} to="/artigos-particulares" onClick={preventDefault}>
                <article className="principal">
                    <img src={'https://blog.bullseye.com.br/wp-content/uploads/elementor/thumbs/pexels-pixabay-128867-1-scaled-pckovkiopg8fpckjohzcsve04oy9t0jj72eo5n8a0o.jpg'} alt="flash"/>
                    <p>Os Juros Simples e Juros Compostos são conceitos muito utilizados na matemática financeira e todo investidor deve entender o que eles significam e quando usar cada um, conheça agora os Juros Simples e Juros Compostos! Os juros são uma remuneração que corresponde ao valor do dinheiro emprestado, ou seja, quando alguém pega um capital emprestado, ela deverá devolver não apenas o valor total, mas uma parte a mais que é referente ao tempo em que o dinheiro ficou em suas mãos.
                    O mesmo acontece para os investimentos, todo investimento possui uma taxa de rentabilidade, que é utilizada nas fórmulas matemáticas dos juros, porém, existe uma grande diferença ente Juros Simples e Juros Compostos.</p>
                </article>
            </Link>
        </Typography>
    )
}

export default MainArticle