import React from 'react'
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {Grid,
        Typography,
} from '@material-ui/core/'



import Button from './style.js'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: 'black',
      color: 'white',
      marginTop: '5%',
      padding: '2%',
    },
    logoTitleBulltopedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    responsivo: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alinhar: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0 3%'
    },
    colorFace: {
        color: 'white',
        padding: '0 1%',
        background: 'rgba(255,255,255,.12)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 1%'
    },
    colorInsta: {
        color: 'white',
        padding: '0 1%',
        background: 'rgba(255,255,255,.12)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 1%'
    },
    colorLinkedin: {
        color: 'white',
        padding: '0 1%',
        background: 'rgba(255,255,255,.12)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 1%'
    },
    colorTitle: {
        color: 'white',
        fontSize: '1.5rem',
        textAlign: 'center',
    },
    colorSubTitle: {
        color: 'white',
        fontSize: '1rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '3% 0',
        fontWeight: '500'
    },
    logoBullseye: {
        width: '7%',
        margin: '4% 1% 4% 3%', 
    },
    colorOfItems: {
        color: 'white'
      },    
    
  }))



const Footer = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid className={classes.responsivo} item xs={12} md={3}>
                    <h3 className={classes.colorTitle}>Educacional</h3>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Ciclo Imobiliário</Button></Link>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Juros Simples e Compostos</Button></Link>
                </Grid>
                <Grid className={classes.responsivo} item xs={12} md={3}>
                    <h3 className={classes.colorTitle}>Simulador</h3>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Ciclo Imobiliário</Button></Link>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Juros Simples e Compostos</Button></Link>
                </Grid>
                <Grid className={classes.responsivo} item xs={12} md={3}>
                    <h3 className={classes.colorTitle}>Notícias</h3>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Ciclo Imobiliário</Button></Link>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Juros Simples e Compostos</Button></Link>
                </Grid>
                <Grid className={classes.responsivo} item xs={12} md={3}>
                    <h3 className={classes.colorTitle}>Artigos</h3>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Ciclo Imobiliário</Button></Link>
                    <Link to='/artigos' className={classes.colorSubTitle} href='#'><Button>Juros Simples e Compostos</Button></Link>
                </Grid>

            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Link className={classes.colorOfItems} to='/'>
                        <div className={classes.logoTitleBulltopedia}>
                            <img className={classes.logoBullseye} src={'https://bullseye.com.br/assets/lp/img/logo.png'} alt='texto'/>
                            <Typography variant="h6" noWrap>
                                Bullseye
                            </Typography>
                        </div>
                    </Link>
                    <div className={classes.alinhar}>
                        <Link className={classes.colorFace} to='http://api.whatsapp.com/send?1=pt_BR&phone=5516991812782'><i class="fa fa-whatsapp fa-lg" aria-hidden="true"></i></Link>
                        <Link className={classes.colorInsta} to='https://www.instagram.com/bullseye.br/'><i class="fa fa-instagram fa-lg" aria-hidden="true"></i></Link>
                        <Link className={classes.colorLinkedin} to='https://www.linkedin.com/company/bullseyebr/?originalSubdomain=br'><i class="fa fa-linkedin-square fa-lg" aria-hidden="true"></i></Link>
                    </div>
                </Grid>
                {/* <Grid className={classes.responsivo} item xs={12} md={6}>
                    <Typography variant="h6" noWrap>
                        Redes Sociais
                    </Typography>
                    <div className={classes.alinhar}>
                        <Link className={classes.colorFace} to='/'><i class="fa fa-facebook-square fa-lg" aria-hidden="true"></i></Link>
                        <Link className={classes.colorInsta} to='/'><i class="fa fa-instagram fa-lg" aria-hidden="true"></i></Link>
                        <Link className={classes.colorLinkedin} to='/'><i class="fa fa-linkedin-square fa-lg" aria-hidden="true"></i></Link>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default Footer