import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import './style.css'


const useStyles = makeStyles((theme) => ({
    transform: {
        color:theme.palette.text.primary,
        padding: 5,
    },
  }));

const SidebarArticle = () => {
    const classes = useStyles()
    return (
        <div className='sidebar'>
            <ul>
                Barra De Navegação
                <Link className={classes.transform} to="/fii"><i class="fa fa-building" aria-hidden="true"></i> FII's</Link>
                <Link className={classes.transform} to="/"><i class="fa fa-university" aria-hidden="true"></i> Ações</Link>
                <Link className={classes.transform} to="/"><i class="fa fa-id-card-o" aria-hidden="true"></i> Cripto</Link>
                <Link className={classes.transform} to="/"><i class="fa fa-newspaper-o" aria-hidden="true"></i> Últimas Notícias</Link>
            </ul>
        </div>
    )
}

export default SidebarArticle