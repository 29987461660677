import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


import './Articles.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    },
    spacerText: {
        padding: theme.spacing(1),
    },
  }))



const ArticlesFive = () => {

    const classes = useStyles()

    return (
            <Link className={classes.textColor} to="/artigos-particulares">
                <article className="secundario">
                    <img className='imagem-dos-artigos' src={'https://blog.bullseye.com.br/wp-content/uploads/2021/08/pexels-kevin-ku-577585-1024x768.jpg'} alt="flash"/>
                    <h1 className={classes.spacerText}>Setores da Bolsa de Valores</h1>
                    <p className={classes.spacerText}>Os setores da Bolsa de Valores são classificações das empresas listadas de acordo com o que fazem e produzem e conhecer quais são estas classificações é muito importante para a tomada de decisão do investidor, conheça agora os setores da bolsa!</p>
                </article>
            </Link>
    )
    
}

export default ArticlesFive