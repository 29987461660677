import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {Paper,
Grid,
} from '@material-ui/core/'


import MainArticle from '../../components/Artigos/MainArticle'
import Articles from '../../components/Artigos/Articles'
import ArticlesTwo from '../../components/Artigos/ArticlesTwo'
import ArticlesThree from '../../components/Artigos/ArticlesThree'
import ArticlesFour from '../../components/Artigos/ArticlesFour'
import ArticlesFive from '../../components/Artigos/ArticlesFive'
import ArticlesSix from '../../components/Artigos/ArticlesSix'
import imagemCurso from '../../assets/imgs/banner.jpeg'
import imagemSimulador from '../../assets/imgs/banner-simulador.jpeg'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  imagens: {
    width: '100%',
    borderRadius: 7,
  },
  paperImagens: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow:'0 4px 6px 0 #33333333',
  },
  paperArticles: {
    textAlign: 'center',
    width:'100%',
    color: theme.palette.text.secondary,
    boxShadow:'0 4px 6px 0 #33333333',
  },
  spacer: {
    marginTop: '3%',
  },
  alinhamento: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const Artigos = () => {
    const classes = useStyles();
    return (
            <section className="container">
                <Grid container spacing={3}>
                    <Grid className={classes.spacer} item xs={12}>
                        <Paper elevation={3} className={classes.paper}><MainArticle /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={6}>
                        <Paper elevation={3} className={classes.paperImagens}><Link to="/"><img className={classes.imagens} src={imagemCurso} alt='curso sobre investimentos'/></Link></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={6}>
                        <Paper elevation={3} className={classes.paperImagens}><Link to="/"><img className={classes.imagens} src={imagemSimulador} alt='curso sobre investimentos'/></Link></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={6}>
                        <Paper elevation={3} className={classes.paperArticles}><ArticlesFour /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={6}>
                        <Paper elevation={3} className={classes.paperArticles}><ArticlesThree /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                        <Paper elevation={3} className={classes.paperArticles}><ArticlesTwo /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                        <Paper elevation={3} className={classes.paperArticles}><ArticlesFive /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                        <Paper elevation={3} className={classes.paperArticles}><ArticlesSix /></Paper>
                    </Grid>
                    <Grid className={classes.alinhamento} item xs={12} sm={6} md={3}>
                        <Paper elevation={3} className={classes.paperArticles}><Articles /></Paper>
                    </Grid>
                </Grid>
            </section>
    )
}
export default Artigos