import React from 'react' 
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {Paper,
Grid,
} from '@material-ui/core/'

import './StandardArticles.css'
import SidebarArticle from '../Sidebar/Index'
import Articles from './Articles'
import ArticlesTwo from './ArticlesTwo'
import ArticlesThree from './ArticlesThree'
import ArticlesFour from './ArticlesFour'



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'start',
      color: theme.palette.text.primary,
      boxShadow: '0 4px 6px 0 #33333333',
    },
    articlesPlus: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        boxShadow: '0 4px 6px 0 #33333333',
        color: theme.palette.text.primary,
    }
  }))


const Standard = () => {

    const classes = useStyles()

    return (
        <>
            <section className='article-information'>
                    <article className='information'>
                        <Grid item xs>
                            <Paper className={classes.paper}><span>Aqui ficará o gênero da informação</span>
                                <h1>Global Bonds</h1>
                                <h2>Breve descrição da notícia</h2>
                                <p>Os Global Bonds são títulos da dívida externa, ou seja, são títulos emitidos por um determinado país que representam a sua dívida e podem ser negociados internacionalmente.

                                Assim, pode ser uma boa opção para os investidores interessados em diversificar o seu capital fora do próprio país.

                                No Brasil, o Tesouro Nacional emite os Global Bonds, conhecidos como títulos globais, dessa forma, o governo financia a sua dívida externa através do capital de investidores de todo o mundo.</p>
                                
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </Paper>
                        </Grid>
                        <Link to="/">Voltar</Link>
                    </article>
                <SidebarArticle />
            </section>
            <section className='articles-plus container'>
                <h3 className='title-plus'>
                    Você também pode gostar
                </h3>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper className={classes.articlesPlus}><Articles /></Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper className={classes.articlesPlus}><ArticlesTwo /></Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper className={classes.articlesPlus}><ArticlesThree /></Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper className={classes.articlesPlus}><ArticlesFour /></Paper>
                    </Grid>

                </Grid>
                
            </section>
        </>
    )
}

export default Standard