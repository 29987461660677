import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


import './Articles.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    },
    spacerText: {
        padding: theme.spacing(1),
    },
  }))



const Articles = () => {

    const classes = useStyles()

    return (
            <Link className={classes.textColor} to="/artigos-particulares">
                <article className="secundario">
                    <img className='imagem-dos-artigos' src={'https://blog.bullseye.com.br/wp-content/uploads/elementor/thumbs/pexels-pratikxox-3222685-scaled-pbw83eth5k5m5cfu1z1svtcj8cffckco295nzv8d6g.jpg'} alt="flash"/>
                    <h1 className={classes.spacerText}>Global Bonds</h1>
                    <p className={classes.spacerText}>Os chamados Global Bonds são títulos de dívida de diversos países diferentes, que permitem ao investidor expor o seu capital a outras economias ao redor do mundo, conheça agora este título!</p>
                </article>
            </Link>
    )
    
}

export default Articles