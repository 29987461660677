import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


import './Articles.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    },
    spacerText: {
        padding: theme.spacing(1),
    },
  }))



const ArticlesTwo = () => {

    const classes = useStyles()

    return (
            <Link className={classes.textColor} to="/artigos-particulares">
                <article className="secundario">
                    <img className='imagem-dos-artigos' src={'https://blog.bullseye.com.br/wp-content/uploads/2021/08/road-4598095_1920-1024x682.jpg'} alt="flash"/>
                    <h1 className={classes.spacerText}>Ciclo Imobiliário</h1>
                    <p className={classes.spacerText}>O mercado imobiliário apresenta um ciclo característico que se repete de tempos em tempos e os investidores podem usar isso para obter melhores resultados.</p>
                </article>
            </Link>
    )
    
}

export default ArticlesTwo