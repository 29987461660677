import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


import './Articles.css'

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.text.primary,
    },
    spacerText: {
        padding: theme.spacing(1),
    },
  }))



const ArticlesSix = () => {

    const classes = useStyles()

    return (
            <Link className={classes.textColor} to="/artigos-particulares">
                <article className="secundario">
                    <img className='imagem-dos-artigos' src={'https://blog.bullseye.com.br/wp-content/uploads/elementor/thumbs/pexels-canva-studio-3194519-scaled-pbrcm28qli6ikaqpxrixih5bi7u7pdwv6a0mog11vc.jpg'} alt="flash"/>
                    <h1 className={classes.spacerText}>IPO (Initial Public Offering)</h1>
                    <p className={classes.spacerText}>O IPO é um evento do mercado financeiro que desperta o interesse dos investidores que desejam obter ganhos com a entrada de empresas na Bolsa de Valores.</p>
                </article>
            </Link>
    )
    
}

export default ArticlesSix