import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {Drawer,
CssBaseline,
AppBar,
Toolbar,
List,
Typography,
Divider,
ListItem,
ListItemText,
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import Button from './style'
import LoginButton from './loginStyle'

const drawerWidth = 240


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'black',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logoBullseye: {
    width: '30%',
    margin: '4% 3%', 
  },
  alinhametoLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  alinhamentoNavbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexGrow: 1,  
  },
  colorOfItems: {
    color: 'white'
  },
  spaceItens: {
    margin: '0 2%'
  }
  
}));

const Navbar = () => {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
    <CssBaseline />
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      >
      <Toolbar >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Link className={classes.colorOfItems} to='/'>
          <Typography className={classes.alinhametoLogo} variant="h6" noWrap>
                  <img className={classes.logoBullseye} src={'https://bullseye.com.br/assets/lp/img/logo.png'} alt='texto'/>
                  Bullseye
          </Typography>
        </Link>
        <div className={classes.alinhamentoNavbar}>
          <Link className={classes.colorOfItems} to='/artigos'>
            <Button>Home</Button>
          </Link>
          <Link className={classes.colorOfItems} to='/artigos'>
            <Button>A Bullseye</Button>
          </Link>
          <Link className={classes.colorOfItems} to='/artigos'>
            <Button>Como Funciona</Button>
          </Link>
          <Link className={classes.colorOfItems} to='/artigos'>
            <Button>Salas Privadas</Button>
          </Link>
            <a href={'https://bullseye.com.br/painel/index.php'} rel="noreferrer" target={'_blank'}>
              <LoginButton>Login</LoginButton>
            </a>
        </div>
      </Toolbar>
    </AppBar>
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {['Cursos', 'Notícias', 'Simulador'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <AttachMoneyIcon /> : <AccountBalanceIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Renda Variável', 'Tesouro Direto', 'Fundos Imobiliários'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <AttachMoneyIcon /> : <AccountBalanceIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      />
      </div>
  )
}

export default Navbar