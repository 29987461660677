import styled from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  border: none;
  margin: 0 1em;
  &:hover {
    border-bottom: 3px solid #58BD58;
    
  }
`
export default Button